import React, { useEffect, useRef } from 'react';

function RotatingDiv(props) {
    const patternDiv = useRef();
    useEffect(() => {
        const scrollRotate = () => {
            const childElement = patternDiv.current.firstChild;
            childElement.style.transform = `rotate(${
                window.pageYOffset / 5
            }deg)`;
        };
        window.addEventListener('scroll', scrollRotate);
        return () => window.removeEventListener('scroll', scrollRotate);
    }, []);
    return <div ref={patternDiv}>{props.children}</div>;
}

export default RotatingDiv;
