import React from 'react';

import CardAboutBrand from '../components/card/cardAboutBrand';
import RotatingDiv from '../components/rotatingDiv/rotatingDiv';
import InlineJams from '../components/product/inlineJams';
import Explore from '../components/explore/explore';
import CardLogo from '../components/card/cardLogo';
import floryptus from '../images/logos/floryptus-logo-slogan.svg';
//import qpetisco from '../images/logos/qpetisco-logo-slogan.svg';

function About() {
    return (
        <>
            <div className="headings-wrapper-about mb-10">
                <h1 className="mb-10">Sobre nós</h1>
                <h3 className="blue-text padding-bottom30">
                    A Physalina é uma marca nacional cuja única finalidade é a
                    comercialização dos produtos criados à base de bagas de
                    Physalis peruviana.
                </h3>
            </div>
            <div className="cards-about-wrapper">
                <div className="our-process-card">
                    <RotatingDiv>
                        <div className="svg-hortela about-brand-pattern"></div>
                    </RotatingDiv>
                    <div className="card-margin">
                        <CardAboutBrand
                            title="O nosso processo"
                            color="green-text"
                            subtitle="Como garantimos a obtenção e oferta de produtos de elevada qualidade?"
                            text={
                                <>
                                    As nossas physalis são plantadas e as suas
                                    bagas colhidas e processadas por nós, por
                                    processo manuais e algo artesanais em
                                    pequenos lotes, mas num cumprimento rigoroso
                                    todas as regras de higiene e segurança
                                    alimentar. <br /> <br />
                                    Através dos nossos métodos de produção
                                    artesanais, conseguimos obter os nossos
                                    produtos livres de corantes e conservantes
                                    artificiais.
                                </>
                            }
                        ></CardAboutBrand>
                    </div>
                </div>
                <div className="our-history-card">
                    <RotatingDiv>
                        <div className="svg-toranja about-brand-pattern"></div>
                    </RotatingDiv>
                    <div className="card-margin">
                        <CardAboutBrand
                            title="Como surgimos"
                            color="red-text"
                            subtitle="Quer-nos conhecer melhor?"
                            text={
                                <>
                                    Na procura das culturas que melhor se
                                    adaptassem às condições edafoclimáticas da
                                    região de Águeda e ao potencial dos solos,
                                    sem descurar a inovação, deparamo-nos com a
                                    physalis peruviana.
                                    <br />
                                    <br />
                                    Após alguns anos a cultivar e comercializar
                                    as bagas “in natura”, surgiu a necessidade
                                    de acrescentar valor à produção, e durante
                                    cerca de dois anos estudámos e testámos
                                    métodos de processamento das bagas.
                                </>
                            }
                            highlight="Assim, nasceu a Physalina."
                        ></CardAboutBrand>
                    </div>
                </div>
            </div>
            <span className="medium-text blue-text please-drag">
                Arraste os produtos &nbsp;
                <i className="fa fa-angle-double-right"></i>
            </span>
            <div className="categories-wrapper">
                <div className="inline-jams-grid">
                    <InlineJams />
                </div>
                <div className="explore-jams">
                    <Explore
                        link="/jamList"
                        text="Descubra os nossos doces"
                    ></Explore>
                </div>
            </div>
            <div className="discover-our-brands">
                <h3 className="blue-text">Já conhece a nossa outra marca?</h3>
                <div className="cards-our-brands-wrapper">
                    <CardLogo
                        alt="Floryptus"
                        src={floryptus}
                        link="https://www.floryptus.pt/"
                    ></CardLogo>
                    {/*<CardLogo alt="Q'Petisco" src={qpetisco} link=""></CardLogo>*/}
                </div>
            </div>
        </>
    );
}

export default About;
