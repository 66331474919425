import React from 'react';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Sidebar from '../../components/sidebar/sidebar';
import DetailedProduct from '../../components/product/detailedProduct';
import DetailsHeading from '../../components/detailsHeading/detailsHeading';
import CardIngredients from '../../components/card/cardIngredients';
import CardNutritional from '../../components/card/cardNutritional';
import SuggestedProductsHeading from '../../components/product/suggestedProductsHeading';
import DiscoverProduct from '../../components/product/discoverProduct';
import HorizontalMenu from '../../components/sidebar/horizontalMenu';
import RotatingDiv from '../../components/rotatingDiv/rotatingDiv';

//import product images
import chocolate from '../../images/products/chocolate.png';
import chocolateJam from '../../images/products/jam-choco.png';

function ChocolateJam() {
    const suggestions = [
        {
            name: 'Bagas com Chocolate',
            photo: chocolate,
            link: '/chocolate',
            className: 'physalina-link',
        },
    ];

    return (
        <>
            <div className="horizontal-menu">
                <HorizontalMenu />
            </div>
            <div className="breadcrumb-container">
                <Breadcrumb
                    home="Home"
                    section="Produtos"
                    category="Doces de Physalis"
                    name="Chocolate"
                ></Breadcrumb>
            </div>
            <div className="product-details-grid">
                <div className="sidebar-menu padding-top30 padding-left50">
                    <Sidebar />
                </div>
                <DetailedProduct
                    photo={chocolateJam}
                    name="Chocolate"
                    color="dark-brown-text"
                    category="Doces de Physalis"
                    description="Eis que surge um doce com forte realce do chocolate que mais se assemelha a um “Creme para Barrar”. Com baixo teor de açúcar é excelente para barrar torradas, pão ou rechear bolos."
                    weight="Frasco de 240g"
                    link="/contacts"
                    buttonColor="btn--chocolate"
                    buttonText="Online"
                ></DetailedProduct>
                <div className="details">
                    <div className="details-title">
                        <DetailsHeading
                            text="Detalhes"
                            type="chocolate"
                        ></DetailsHeading>
                    </div>
                    <div className="cards">
                        <div className="card-ingridients-container">
                            <CardIngredients
                                percentage="58%"
                                sugar="yes"
                                salt="yes"
                                specialIngredient="Chocolate negro"
                                secondSpecialIngredient="Sumo de limão"
                            ></CardIngredients>
                            <RotatingDiv>
                                <div className="svg-chocolate-jam stripes-pattern-container"></div>
                            </RotatingDiv>
                        </div>
                        <div className="card-nutritional-container">
                            <CardNutritional
                                energy="1112 kj / 265 kcal"
                                lipids="9.17 g"
                                saturados="2.12 g"
                                hydrates="39.40 g"
                                sugars="25.70 g"
                                fiber="10.06 g"
                                proteins="6.27 g"
                                salt="0.08 g"
                            ></CardNutritional>
                            <RotatingDiv>
                                <div className="svg-chocolate-jam stripes-pattern-container2"></div>
                            </RotatingDiv>
                        </div>
                    </div>
                </div>
                <div className="suggestions-heading">
                    <SuggestedProductsHeading
                        question="Adora chocolate?"
                        answer="Experimente:"
                        color="dark-brown-text"
                    ></SuggestedProductsHeading>
                </div>
                <div className="discover-section-grid">
                    {suggestions.map((discoverProduct, idx) => (
                        <DiscoverProduct
                            key={idx}
                            discoverProduct={discoverProduct}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}

export default ChocolateJam;
