import React from 'react';

function Textarea(props) {
    return (
        <div className="textarea">
            <p className="label-textfield">{props.labelTextarea}</p>
            <textarea
                className={props.styleName}
                placeholder={props.placeholder || 'Placeholder'}
                type="text"
                id={props.id}
                onChange={event => props.onChange(event.target.value)}
            ></textarea>
        </div>
    );
}
export default Textarea;
