import React from 'react';

function CardLogo(props) {
    return (
        <div className="logo-card">
            <a
                href={props.link}
                target="_blank"
                rel="noopener noreferrer"
                className={props.className}
            >
                <img alt={props.alt} src={props.src} />
            </a>
        </div>
    );
}
export default CardLogo;
