import React from 'react';

function CardAboutBrand(props) {
    return (
        <div className="card-about-brand">
            <h3 className={`mb-10 ${props.color}`}>{props.title}</h3>
            <p className="large-text blue-text mb-20">{props.subtitle}</p>
            <p className="medium-text">{props.text}</p>
            {props.highlight && (
                <h3 className="blue-text mt-20">{props.highlight}</h3>
            )}
        </div>
    );
}

export default CardAboutBrand;
