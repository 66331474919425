import React from 'react';

function detailsHeading(props) {
    let typeBackground;
    switch (props.type) {
        case 'original':
            typeBackground = 'background-orange';
            break;
        case 'hortela':
            typeBackground = 'background-green';
            break;
        case 'anis':
            typeBackground = 'background-light-brown';
            break;
        case 'chocolate':
            typeBackground = 'background-dark-brown';
            break;
        case 'eucalipto':
            typeBackground = 'background-dark-green';
            break;
        case 'vinhoPorto':
            typeBackground = 'background-purple';
            break;
        case 'toranja':
            typeBackground = 'background-red';
            break;
        default:
            typeBackground = 'background-blue';
            break;
    }

    return (
        <h2
            className={`details-heading white-text padding-top40 ${typeBackground}`}
        >
            Detalhes
        </h2>
    );
}

export default detailsHeading;
