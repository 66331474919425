import React from 'react';
import { Link } from 'react-router-dom';

function DiscoverProduct(props) {
    const { discoverProduct } = props;

    return (
        <div className="discover-product">
            <Link
                to={discoverProduct.link}
                className={discoverProduct.className}
            >
                <img
                    className="discover-product-image"
                    src={discoverProduct.photo}
                    alt="ProductDiscover"
                />
                <br></br>
                <div className="discover-product-name">
                    <p>{discoverProduct.name}</p>
                </div>
                <div className="image-hover"></div>
            </Link>
        </div>
    );
}

export default DiscoverProduct;
