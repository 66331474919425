import React from 'react';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Sidebar from '../../components/sidebar/sidebar';
import DetailedProduct from '../../components/product/detailedProduct';
import DetailsHeading from '../../components/detailsHeading/detailsHeading';
import CardIngredients from '../../components/card/cardIngredients';
import CardNutritional from '../../components/card/cardNutritional';
import SuggestedProductsHeading from '../../components/product/suggestedProductsHeading';
import DiscoverProduct from '../../components/product/discoverProduct';
import HorizontalMenu from '../../components/sidebar/horizontalMenu';

//import product images
import chocolate from '../../images/products/chocolate-choco.png';
import chocolateJam from '../../images/products/jam-choco.png';

function Chocolate() {
    const suggestions = [
        {
            name: 'Doce de Physalis com Chocolate',
            photo: chocolateJam,
            link: '/chocolatejam',
            className: 'physalina-link',
        },
    ];

    return (
        <>
            <div className="horizontal-menu">
                <HorizontalMenu />
            </div>
            <div className="breadcrumb-container">
                <Breadcrumb
                    home="Home"
                    section="Produtos"
                    category="Bagas de Physalis"
                    name="Bagas com Chocolate"
                ></Breadcrumb>
            </div>
            <div className="product-details-grid">
                <div className="sidebar-menu padding-top30 padding-left50">
                    <Sidebar />
                </div>
                <DetailedProduct
                    photo={chocolate}
                    name="Bagas desidratadas c/ chocolate"
                    color="dark-brown-text"
                    category="Bagas de Physalis"
                    description="Se as Bagas de Physalis Desidratadas por si só são enigmáticas, quando envoltas em chocolate são uma explosão de sabor inigualável. O agridoce das “passas” envolto pelo aveludado do chocolate confere-lhe um toque único, capaz de despertar até as papilas gustativas mais tímidas."
                    weight="50g ou 125g"
                    buttonText="50g"
                    link="/contacts"
                    secondLink="/contacts"
                    buttonColor="btn--chocolate"
                    berries="yes"
                ></DetailedProduct>
                <div className="details">
                    <div className="details-title">
                        <DetailsHeading
                            text="Detalhes"
                            type="chocolate"
                        ></DetailsHeading>
                    </div>
                    <div className="cards">
                        <div className="card-ingridients-container">
                            <CardIngredients
                                percentage="50%"
                                ingredient="Chocolate negro (70% cacau)"
                                sulfitos="yes"
                            ></CardIngredients>
                            <div className="svg-chocolate solid-pattern-container"></div>
                        </div>
                        <div className="card-nutritional-container">
                            <CardNutritional
                                energy="1186 kj / 280 kcal"
                                lipids="0.51 g"
                                saturados="0.05 g"
                                hydrates="65.75 g"
                                sugars="55.80 g"
                                fiber="3.50 g"
                                proteins="1.25 g"
                                salt="0.40 g"
                            ></CardNutritional>
                            <div className="svg-chocolate solid-pattern-container2"></div>
                        </div>
                    </div>
                </div>
                <div className="suggestions-heading">
                    <SuggestedProductsHeading
                        question="Adora chocolate?"
                        answer="Derreta-se com este doce:"
                        color="dark-brown-text"
                    ></SuggestedProductsHeading>
                </div>
                <div className="discover-section-grid">
                    {suggestions.map((discoverProduct, idx) => (
                        <DiscoverProduct
                            key={idx}
                            discoverProduct={discoverProduct}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}

export default Chocolate;
