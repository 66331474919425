import React from 'react';

function CardNutritional(props) {
    return (
        <div className="card">
            <div className="title">
                <p>Valores nutricionais por 100g</p>
            </div>
            <div className="nutricional-content">
                <div className="energy">
                    <span>Energia</span>
                    <span> {props.energy}</span>
                </div>
                <div className="lipids">
                    <span>Lípidos</span>
                    <span> {props.lipids}</span>
                    &nbsp;
                    <span>{`(saturados: ${props.saturados})`}</span>
                </div>
                <div className="hydrates">
                    <span>Hidratos de Carbono</span>
                    <span> {props.hydrates}</span>
                    &nbsp;
                    <span>{`(açúcares: ${props.sugars})`}</span>
                </div>
                <div className="fiber">
                    <span>Fibra</span>
                    <span> {props.fiber}</span>
                </div>
                <div className="proteins">
                    <span>Proteínas</span>
                    <span> {props.proteins}</span>
                </div>
                <div className="salt">
                    <span>Sal</span>
                    <span> {props.salt}</span>
                </div>
            </div>
        </div>
    );
}
export default CardNutritional;
