import React from 'react';

function SuggestedProductsHeading(props) {
    return (
        <>
            <h3 className="blue-text">{props.question}</h3>
            <h3 className={props.color}>{props.answer}</h3>
        </>
    );
}

export default SuggestedProductsHeading;
