import React from 'react';
import { Link } from 'react-router-dom';

function SearchBar() {
    return (
        <div className="searchbar-container">
            <form className="searchbar-input">
                <input type="text" size="20" placeholder="Pesquisa" />
                <Link to="/searchResults">
                    {' '}
                    <div className="searchbar-icon">
                        <i className="fa fa-search"></i>
                    </div>
                </Link>
            </form>
        </div>
    );
}
export default SearchBar;
