import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../images/logos/physalina-logo-slogan.svg';
import floryptusLogo from '../../images/logos/floryptus-logo.svg';
//import qpetiscoLogo from '../../images/logos/qpetisco-logo.svg';

function Footer() {
    return (
        <footer>
            <div className="footer-content">
                <div className="footer-section logo">
                    <img src={logo} alt="Physalina Logo" id="physalina-logo" />
                </div>
                <div className="footer-section contacts">
                    <p className="normal-text">
                        <b>Contactos</b>
                    </p>
                    <p className="small-text">
                        <i className="fa fa-phone mt-10"></i> &nbsp; (+351) 937
                        080 911
                    </p>
                    <p className="small-text">
                        <i className="fa fa-envelope mt-10"></i> &nbsp;
                        info@floryptus.pt
                    </p>
                    <p className="small-text">
                        <i className="fa fa-map-marker mt-10"></i> &nbsp; Rua
                        Herculano Coelho, 18
                    </p>
                    <p className="small-text">
                        3080-847 Figueira da Foz, Portugal
                    </p>
                </div>
                <div className="footer-section links">
                    <p className="normal-text">
                        <b>Links</b>
                    </p>
                    <Link to="/termsConditions" className="small-text">
                        Termos e condições
                    </Link>
                    <Link to="/about" className="small-text">
                        Sobre nós
                    </Link>
                    {/* <a
                        href="/contacts"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="small-text"
                    >
                        Loja Online
                    </a> */}
                </div>
                <div className="footer-section brands">
                    <p className="small-text">Descubra a nossa marca</p>
                    <br></br>
                    <div className="mb-20">
                        <a
                            href="https://www.floryptus.pt/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={floryptusLogo} alt="Floryptus Logo" />
                        </a>
                    </div>
                    {/*
                    <img src={qpetiscoLogo} alt="Q'Petisco Logo" />
                    */}
                </div>
            </div>
            <div className="footer-bottom">
                &copy; {new Date().getFullYear()} Floryptus, Lda.
            </div>
        </footer>
    );
}
export default Footer;
