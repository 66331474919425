import React from 'react';

const STYLES = [
    'btn--primary',
    'btn--secondary',
    'btn--original',
    'btn--toranja',
    'btn--vinho-porto',
    'btn--anis-chocolate',
    'btn--chocolate',
    'btn--hortela',
    'btn--eucalipto',
];

const SIZES = ['btn--normal', 'btn--medium', 'btn--large'];

export const Button = ({
    children,
    type,
    href,
    onClick,
    buttonStyle,
    buttonSize,
}) => {
    const checkButtonStyles = STYLES.includes(buttonStyle)
        ? buttonStyle
        : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    if (type === 'anchor') {
        return (
            <a
                href={href}
                className={`btn ${checkButtonStyles} ${checkButtonSize}`}
                // target="_blank"
                rel="noopener noreferrer"
            >
                {children}
            </a>
        );
    }

    return (
        <button
            className={`btn ${checkButtonStyles} ${checkButtonSize}`}
            onClick={onClick}
            type={type}
        >
            {children}
        </button>
    );
};
export default Button;
