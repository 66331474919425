import React from 'react';
import Product from '../../components/product/generalProduct';
import DiscoverProduct from '../../components/product/discoverProduct';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import FilterDropdown from '../../components/filterDropdown/filterDropdown';

//import product images
//import originalberry from '../../images/products/originalberry.png';
//import chocolate from '../../images/products/chocolate-choco.png';
import originaljam from '../../images/products/original.png';
import hortela from '../../images/products/hortela.png';
import toranja from '../../images/products/toranja.png';
import berriesSauce from '../../images/products/berries-sauce2.png';
import pickles from '../../images/products/pickles.png';

function BerryList(props) {
    const berries = [
        {
            name: 'Bagas em Calda',
            photo: berriesSauce,
            link: '/sauce',
            className: 'original-link',
        },
        {
            name: 'Bagas em Pickles',
            photo: pickles,
            link: '/pickles',
            className: 'original-link',
        },
    ];

    const jams = [
        { name: 'Original', photo: originaljam, link: '/originalJam' },
        { name: 'Hortelã', photo: hortela, link: '/hortela' },
        { name: 'Toranja', photo: toranja, link: '/toranja' },
    ];

    return (
        <div className="berry-list">
            <Breadcrumb
                home="Home"
                section="Produtos"
                category="Bagas de Physalis"
            ></Breadcrumb>
            <FilterDropdown />
            <div className="title-subtitle">
                <h2 className="mt-10 mb-10">
                    Conservas e outros <br /> de Physalis
                </h2>
                {/* <p className="medium-text padding-bottom30">
                    Esta categoria abrange as bagas desidratadas (passas), as
                    bagas em calda e os pickles de Physalis. Todas elas são uma
                    forma natural de usufruir das bagas de Physalis todo o ano,
                    oferecendo praticamente todas as propriedades e benefícios
                    da fruta fresca.
                </p> */}
            </div>
            <div className="product-list-grid">
                {berries.map((product, idx) => (
                    <Product key={idx} product={product} />
                ))}
            </div>
            {/* <div className="online-store-button">
                <div className="online-store-button">
                    <Button
                        href="/contacts"
                        type="anchor"
                        buttonStyle="btn--primary"
                        buttonSize="btn--large"
                    >
                        <i className="fa fa-shopping-basket mr-10"></i> Loja
                        Online
                    </Button>
                </div>
            </div> */}
            <div className="berry-usage mt-50">
                <h3 className="large-text blue-text">
                    Sugestões de utilização
                </h3>
                <div className="product-usage-suggestions mt-20 mb-50">
                    <div>
                        <p className="navbar-title">Snack</p>
                        <p className="normal-text blue-text mt-5 mb-10">
                            Podem ser saboreadas de forma simples, como snack ou
                            aperitivo. As passas originais são alternativas às
                            tradicionais passas natalícias. Já as de chocolate
                            são uma excelente alternativa às amêndoas da Páscoa.
                        </p>
                    </div>
                    <div>
                        <p className="navbar-title">Cereais</p>
                        <p className="normal-text blue-text mt-5 mb-10">
                            Podem acompanhar cereais, granola ou iogurte.
                        </p>
                        <p className="navbar-title">Bebidas</p>
                        <p className="normal-text blue-text mt-5 mb-10">
                            Acompanham perfeitamente espumante ou gin.
                        </p>
                    </div>
                </div>
            </div>
            <h3 className="grey-text">Descubra os nossos doces</h3>
            <div className="discover-section-grid">
                {jams.map((discoverProduct, idx) => (
                    <DiscoverProduct
                        key={idx}
                        discoverProduct={discoverProduct}
                    />
                ))}
            </div>
        </div>
    );
}

export default BerryList;
