import React from 'react';
import { Link } from 'react-router-dom';

import originaljam from '../images/products/original.png';
import originalberry from '../images/products/originalberry.png';
import Product from '../components/product/generalProduct';
import NoResults from '../components/noResults/noResults';
import FilterDropdown from '../components/filterDropdown/filterDropdown';

function SearchResults() {
    const results = [
        {
            name: 'Original',
            photo: originaljam,
            link: '/originalJam',
            className: 'original-link',
            category: 'Doces de Physalis',
        },
        {
            name: 'Original',
            photo: originalberry,
            link: '/originalBerry',
            className: 'original-berry-link',
            category: 'Bagas desidratadas',
        },
    ];

    const searchedProduct = 'original';

    const renderText = () => (
        <p className="medium-text mt-20 mb-50">
            Pesquisou por {` "${searchedProduct}" `}
        </p>
    );

    if (results.length === 0) {
        return (
            <div className="no-results-page">
                <Link to="/">
                    <div className="go-back">
                        <span className="normal-text mr-10">
                            <i className="fa fa-arrow-left"></i>
                        </span>
                        <span className="small-text blue-text">Voltar</span>
                    </div>
                </Link>
                <h1 className="blue-text mt-10">Resultados</h1>
                <NoResults />;
            </div>
        );
    }

    return (
        <div className="search-results-page">
            <Link to="/">
                <div className="go-back">
                    <span className="normal-text mr-10">
                        <i className="fa fa-arrow-left"></i>
                    </span>
                    <span className="small-text blue-text">Voltar</span>
                </div>
            </Link>
            <FilterDropdown />
            <h1 className="blue-text mt-10">Resultados</h1>
            {renderText()}
            <div className="product-list-grid mb-100">
                {results.map((result, idx) => (
                    <Product key={idx} product={result} />
                ))}
            </div>
        </div>
    );
}

export default SearchResults;
