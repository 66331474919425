import React from 'react';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Sidebar from '../../components/sidebar/sidebar';
import DetailedProduct from '../../components/product/detailedProduct';
import DetailsHeading from '../../components/detailsHeading/detailsHeading';
import CardIngredients from '../../components/card/cardIngredients';
import CardNutritional from '../../components/card/cardNutritional';
//import SuggestedProductsHeading from '../../components/product/suggestedProductsHeading';
//import DiscoverProduct from '../../components/product/discoverProduct';
import HorizontalMenu from '../../components/sidebar/horizontalMenu';
import RotatingDiv from '../../components/rotatingDiv/rotatingDiv';

//import product images
import hortela from '../../images/products/hortela.png';
//import originaljam from '../../images/products/original.png';

function Hortela() {
    /*const suggestions = [
        {
            name: 'Snack de orégãos e flor de sal',
            photo: originaljam,
            link: '/originalberry',
            className: 'qpetisco-link',
        },
    ];*/

    return (
        <>
            <div className="horizontal-menu">
                <HorizontalMenu />
            </div>
            <div className="breadcrumb-container">
                <Breadcrumb
                    home="Home"
                    section="Produtos"
                    category="Doces de Physalis"
                    name="Hortelã"
                ></Breadcrumb>
            </div>
            <div className="product-details-grid">
                <div className="sidebar-menu padding-top30 padding-left50">
                    <Sidebar />
                </div>
                <DetailedProduct
                    photo={hortela}
                    name="Hortelã"
                    color="green-text"
                    category="Doces de Physalis"
                    description="Para quem aprecia os aromas aveludados da mentha, eis a combinação perfeita,
                    pois dela transparecem os aromas cítricos da Physalis, arredondados e suavizados pela suavidade da hortelã."
                    weight="Frasco de 245g"
                    link="/contacts"
                    buttonColor="btn--hortela"
                    buttonText="Online"
                ></DetailedProduct>
                <div className="details">
                    <div className="details-title">
                        <DetailsHeading
                            text="Detalhes"
                            type="hortela"
                        ></DetailsHeading>
                    </div>
                    <div className="cards">
                        <div className="card-ingridients-container">
                            <CardIngredients
                                percentage="60%"
                                sugar="yes"
                                salt="yes"
                                specialIngredient="Hortelã (Mentha spicata)"
                            ></CardIngredients>
                            <RotatingDiv>
                                <div className="svg-hortela stripes-pattern-container"></div>
                            </RotatingDiv>
                        </div>
                        <div className="card-nutritional-container mb-100">
                            <CardNutritional
                                energy="1193 kj / 291 kcal"
                                lipids="0.45 g"
                                saturados="0.02 g"
                                hydrates="65.30 g"
                                sugars="54.84 g"
                                fiber="3.95 g"
                                proteins="2.05 g"
                                salt="0.35 g"
                            ></CardNutritional>
                            <RotatingDiv>
                                <div className="svg-hortela stripes-pattern-container2"></div>
                            </RotatingDiv>
                        </div>
                    </div>
                </div>
                {/*
                <div className="suggestions-heading">
                    <SuggestedProductsHeading
                        question="Adepto de plantas e ervas aromáticas?"
                        answer="Experimente:"
                        color="green-text"
                    ></SuggestedProductsHeading>
                </div>
                <div className="discover-section-grid">
                    {suggestions.map((discoverProduct, idx) => (
                        <DiscoverProduct
                            key={idx}
                            discoverProduct={discoverProduct}
                        />
                    ))}
                    </div>*/}
            </div>
        </>
    );
}

export default Hortela;
