import React from 'react';
import AOS from 'aos';

import RotatingBanner from '../components/product/rotatingBanner';
import LandingHeader from '../components/landingHeader/landingHeader';
import CardProduct from '../components/card/cardProduct';
import Flavour from '../components/flavour/flavour';
//import Input from '../components/input/input';
//import Button from '../components/button/button';

import original from '../images/products/original.png';
//import vinhoporto from '../images/products/vinhoporto.png';
import originalBerry from '../images/products/originalberry.png';
import lightSpicy from '../images/products/picante-timido.png';
import spicy from '../images/products/picante-audaz.png';
import cinnamon from '../images/products/com-canela.png';

import flavourHortela from '../images/products/flavour/flavour-hortela.png';
import flavourVinhoPorto from '../images/products/flavour/flavour-vinhoporto.png';
import flavourEucalipto from '../images/products/flavour/flavour-eucalipto.png';
import flavourAnis from '../images/products/flavour/flavour-anis.png';
import flavourToranja from '../images/products/flavour/flavour-toranja.png';
import flavourChocolate from '../images/products/flavour/flavour-chocolate.png';

function LandingPage() {
    AOS.init();

    return (
        <main>
            <div
                className="introduction-text"
                data-aos="zoom-in"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="300"
                data-aos-easing="ease-in-out"
            >
                <div className="svg-original landing-pattern"></div>
                <p className="large-text introduction-slogan">
                    O melhor da physalis
                </p>
                <h2 className="blue-text">
                    Confeção de produtos à base <br /> das Bagas (fruta) de{' '}
                    <i>Physalis</i> peruviana
                </h2>
                <p className="medium-text description">
                    As categorias dos nossos produtos e os seus sabores são
                    facilmente reconhecidos através dos padrões coloridos a eles
                    associados. <br /> Descubra-os aqui:
                </p>
            </div>
            <RotatingBanner />
            <section className="newest-products-section">
                <LandingHeader type="orange" title="Novidades"></LandingHeader>
                <div
                    className="product-cards-grid"
                    data-aos="zoom-in-up"
                    data-aos-offset="200"
                    data-aos-delay="20"
                    data-aos-duration="600"
                    data-aos-easing="ease-in-out"
                >
                    <CardProduct
                        link="/lightSpicy"
                        name="Picante Tímido"
                        image={lightSpicy}
                        alt="Picante Tímido"
                        category="Desidratados de Physalis"
                        externalLink="/contacts"
                    ></CardProduct>
                    <CardProduct
                        link="/spicy"
                        name="Picante Audaz"
                        image={spicy}
                        alt="Picante Audaz"
                        category="Desidratados de Physalis"
                        externalLink="/contacts"
                    ></CardProduct>
                    <CardProduct
                        link="/cinnamon"
                        name="c/ Canela"
                        image={cinnamon}
                        alt="c/ Canela"
                        category="Desidratados de Physalis"
                        externalLink="/contacts"
                    ></CardProduct>
                </div>
            </section>
            <section className="best-sellers-section">
                <LandingHeader
                    type="blue"
                    title="Mais vendidos"
                ></LandingHeader>
                <div
                    className="product-cards-grid"
                    data-aos="zoom-in-up"
                    data-aos-offset="200"
                    data-aos-delay="20"
                    data-aos-duration="600"
                    data-aos-easing="ease-in-out"
                >
                    <CardProduct
                        link="/originalJam"
                        name="Doce de Physalis"
                        image={original}
                        alt="DoceOriginal"
                        category="Doce de Physalis"
                        externalLink="/contacts"
                    ></CardProduct>
                    {/* <CardProduct
                        link="/vinhoPorto"
                        name="c/ Vinho do Porto"
                        image={vinhoporto}
                        alt="VinhoPorto"
                        category="Doce de Physalis"
                        externalLink="/contacts"
                    ></CardProduct> */}
                    <CardProduct
                        link="/originalBerry"
                        name="Baga desidratada"
                        image={originalBerry}
                        alt="BagaOriginal"
                        category="Baga desidratada"
                        externalLink="/contacts"
                    ></CardProduct>
                </div>
            </section>
            <section className="flavours-section mb-100">
                <h2>
                    A <i>Physalis</i> <br /> é combinada com...
                </h2>
                <div
                    className="flavours-section-grid"
                    data-aos="zoom-in-up"
                    data-aos-offset="200"
                    data-aos-delay="200"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                >
                    <div className="flavour-margin-top">
                        <Flavour
                            patternStyle="svg-hortela"
                            image={flavourHortela}
                            flavourImage="hortela-flavour"
                            alt="SaborHortela"
                            flavourName="Hortelã"
                        ></Flavour>
                    </div>
                    <Flavour
                        patternStyle="svg-vinho-porto"
                        image={flavourVinhoPorto}
                        flavourImage="vinho-porto-flavour"
                        alt="SaborVinhoPorto"
                        flavourName="Vinho"
                    ></Flavour>
                    <div className="flavour-margin-top">
                        <Flavour
                            patternStyle="svg-eucalipto"
                            image={flavourEucalipto}
                            flavourImage="eucalipto-flavour"
                            alt="SaborEucalipto"
                            flavourName="Eucalipto"
                        ></Flavour>
                    </div>
                    <div className="flavour-margin-top">
                        <Flavour
                            patternStyle="svg-anis"
                            image={flavourAnis}
                            flavourImage="anis-flavour"
                            alt="SaborAnis"
                            flavourName="Anis"
                        ></Flavour>
                    </div>
                    <Flavour
                        patternStyle="svg-toranja"
                        image={flavourToranja}
                        flavourImage="toranja-flavour"
                        alt="SaborToranja"
                        flavourName="Toranja"
                    ></Flavour>
                    <div className="flavour-margin-top">
                        <Flavour
                            patternStyle="svg-chocolate"
                            image={flavourChocolate}
                            flavourImage="chocolate-flavour"
                            alt="SaborChocolate"
                            flavourName="Chocolate"
                        ></Flavour>
                    </div>
                </div>
            </section>
            {/*<section className="newsletter">
                <h2>
                    Faça parte da nossa <i>newsletter</i>
                </h2>
                <p className="medium-text newsletter-description">
                    Subscreva para ter vantagens nas suas compras <br /> e para
                    estar a par das novidades!
                </p>
                <div className="newsletter-input-wrapper">
                    <Input
                        labelInput="E-mail"
                        placeholder="O meu endereço de e-mail"
                        styleName="newsletter-input"
                    ></Input>
                    <div className="button">
                        <Button
                            buttonStyle="btn--primary"
                            buttonSize="btn--normal"
                        >
                            Subscrever
                        </Button>
                    </div>
                </div>
    </section>*/}
        </main>
    );
}

export default LandingPage;
