import React, { useState } from 'react';
import Checkbox from '../checkbox/checkbox';
import onClickOutside from 'react-onclickoutside';

function FilterDropdown() {
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };
    const [showMenu, setShowMenu] = useState(false);

    FilterDropdown.handleClickOutside = () => setShowMenu(false);
    return (
        <div className="dropdown-wrapper">
            <div className="filter-input" tabIndex="0" onClick={toggleMenu}>
                <span className="placeholder-text blue-text">Filtrar por</span>
                <span className="blue-text">
                    <i className="fa fa-sort-down fa-lg"></i>
                </span>
            </div>
            {showMenu && (
                <div className="filter-input-content blue-text">
                    <p className="small-text-semibold grey-text mt-10 mb-10">
                        Categoria
                    </p>
                    <Checkbox option="Doces de Physalis"></Checkbox>
                    <Checkbox option="Bagas desidratadas"></Checkbox>
                    <p className="small-text-semibold grey-text mt-20 mb-10">
                        Sabor
                    </p>
                    <Checkbox option="Physalis Original"></Checkbox>
                    <Checkbox option="Hortelã"></Checkbox>
                    <Checkbox option="Anis"></Checkbox>
                    <Checkbox option="Vinho do Porto"></Checkbox>
                    <Checkbox option="Toranja"></Checkbox>
                    <Checkbox option="Chocolate"></Checkbox>
                </div>
            )}
        </div>
    );
}

const clickOutsideConfig = {
    handleClickOutside: () => FilterDropdown.handleClickOutside,
};

export default onClickOutside(FilterDropdown, clickOutsideConfig);
