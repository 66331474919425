import React from 'react';
import { Link } from 'react-router-dom';

import originalBerry from '../../images/products/originalberry.png';
import chocolate from '../../images/products/chocolate.png';

function InlineJams() {
    return (
        <div className="inline-berries-grid">
            <Link to="/originalBerry" className="original-berry-inline">
                <img src={originalBerry} alt="DoceOriginal" />
                <div className="inline-berries-hover"></div>
            </Link>
            <Link to="/chocolate" className="chocolate-inline">
                <img src={chocolate} alt="Chocolate" className="img100" />
                <div className="inline-berries-hover"></div>
            </Link>
        </div>
    );
}

export default InlineJams;
