import React from 'react';

function Flavour(props) {
    const { flavourImage, flavourName } = props;

    return (
        <div className="flavour">
            <div className="flavour-patterns-wrapper">
                <div className={`${props.patternStyle} flavour-pattern`}></div>
            </div>
            <img
                className={`${flavourImage} flavour-image`}
                src={props.image}
                alt={props.alt}
            />
            <h3 className="blue-text">{flavourName}</h3>
        </div>
    );
}

export default Flavour;
