import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/button/button';

function CardProduct(props) {
    const { link, name, image, alt, category, externalLink } = props;

    return (
        <div className="card-product">
            <Link to={link}>
                <div className="card-product-name">
                    <span className="large-text blue-text mr-10">{name}</span>
                    <span className="small-text blue-text">
                        <i className="fa fa-chevron-right"></i>
                    </span>
                </div>
            </Link>
            <div className="card-product-layout">
                <div className="card-product-image">
                    <Link to={link}>
                        <img
                            className="resized-product-image"
                            src={image}
                            alt={alt}
                        />
                    </Link>
                </div>
                <div className="card-product-info">
                    <p className="normal-text blue-text mt-10 mb-20 text-centered">
                        {category}
                    </p>
                    <Button
                        href={externalLink}
                        type="anchor"
                        buttonStyle="btn--secondary"
                        buttonSize="btn--normal"
                    >
                        <i className="fa fa-shopping-basket mr-10"></i> Comprar
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default CardProduct;
