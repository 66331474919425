import React, { useState } from "react";
import Contact from '../components/contact/contact';
import RotatingDiv from '../components/rotatingDiv/rotatingDiv';
import Input from '../components/input/input';
import Textarea from '../components/textarea/textarea';
import Button from '../components/button/button';
import Explore from '../components/explore/explore';

function About() {
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')


    return (
        <>
            <div className="headings-wrapper-contacts">
                <h1>Contactos</h1>
                <p className="medium-text mt-10">
                    Estamos ao seu dispor para prestar informações
                    <br /> e esclarecer dúvidas.
                </p>
            </div>
            <div className="contacts-grid">
                <div className="brand-contacts">
                    <Contact
                        sentence="Entre em contacto connosco"
                        contact="(+351) 937 080 911"
                    ></Contact>
                    <Contact
                        sentence="Envie-nos um e-mail"
                        contact="info@physalina.pt"
                    ></Contact>
                    <Contact
                        sentence="Saiba onde estamos"
                        contact="Rua Herculano Coelho, nº18 3080-847 Figueira da Foz"
                    ></Contact>
                    <Contact
                        sentence="Siga-nos nas redes sociais"
                        icon="fa fa-facebook-square"
                        link="https://www.facebook.com/physalina.pt/"
                    >
                        >
                    </Contact>
                </div>
                <RotatingDiv>
                    <div className="svg-hortela base-pattern bottom-pattern"></div>
                </RotatingDiv>
                <div className="contact-card">
                    <p className="large-text blue-text mb-30">
                        Tem alguma dúvida ou sugestão?
                    </p>
                    <div className="contact-card-item">
                        <Input
                            id="subject"
                            labelInput="Assunto"
                            placeholder="A que diz respeito?"
                            styleName="input-width"
                            onChange={setSubject}
                        ></Input>
                    </div>
                    <div className="contact-card-item">
                        <Textarea
                            id="message"
                            labelTextarea="Mensagem"
                            placeholder="Exponha aqui o seu comentário"
                            styleName="textarea-size"
                            onChange={setMessage}
                        ></Textarea>
                    </div>
                    <div className="contact-card-button">
                        <Button
                            type="anchor"
                            buttonStyle="btn--primary"
                            buttonSize="btn--large"
                            href={`mailto:info@floryptus.pt?subject=${subject}&body=${message}`}
                        >
                            Enviar
                        </Button>
                    </div>
                </div>
                <RotatingDiv>
                    <div className="svg-original base-pattern top-pattern"></div>
                </RotatingDiv>
            </div>
            <Explore
                text="Explore os nossos produtos"
                link="/productCategories"
            ></Explore>
        </>
    );
}

export default About;
