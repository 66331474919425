import React from 'react';
import Product from '../../components/product/generalProduct';
import DiscoverProduct from '../../components/product/discoverProduct';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import FilterDropdown from '../../components/filterDropdown/filterDropdown';

//import product images
import originaljam from '../../images/products/original.png';
import hortela from '../../images/products/hortela.png';
import anis from '../../images/products/anis.png';
import eucalipto from '../../images/products/eucalipto.png';
//import vinhoporto from '../../images/products/vinhoporto.png';
import toranja from '../../images/products/toranja.png';
import originalberry from '../../images/products/originalberry.png';
import chocolate from '../../images/products/chocolate.png';
import extra from '../../images/products/extra.png';
import chocolateJam from '../../images/products/jam-choco.png';

function JamList(props) {
    const jams = [
        {
            name: 'Doce de Physalis',
            photo: originaljam,
            link: '/originalJam',
            className: 'original-link',
        },
        {
            name: 'c/ Hortelã',
            photo: hortela,
            link: '/hortela',
            className: 'hortela-link',
        },
        {
            name: 'c/ Anis',
            photo: anis,
            link: '/anis',
            className: 'anis-link',
        },
        {
            name: 'c/ Eucalipto',
            photo: eucalipto,
            link: '/eucalipto',
            className: 'eucalipto-link',
        },
        /* {
            name: 'c/ Vinho do Porto',
            photo: vinhoporto,
            link: '/vinhoporto',
            className: 'vinho-porto-link',
        }, */
        {
            name: 'c/ Toranja',
            photo: toranja,
            link: '/toranja',
            className: 'toranja-link',
        },
        {
            name: 'c/ Chocolate',
            photo: chocolateJam,
            link: '/chocolateJam',
            className: 'chocolate-link',
        },
        {
            name: 'Compota Extra de Physalis',
            photo: extra,
            link: '/originalExtraJam',
            className: 'original-link',
        },
    ];

    const berries = [
        {
            name: 'Original',
            photo: originalberry,
            link: '/originalberry',
            className: 'originalberry-link',
        },
        {
            name: 'Chocolate',
            photo: chocolate,
            link: '/chocolate',
            className: 'chocolate-link',
        },
    ];

    return (
        <div className="jam-list">
            <Breadcrumb
                home="Home"
                section="Produtos"
                category="Doces de Physalis"
            ></Breadcrumb>
            <FilterDropdown />
            <div className="title-subtitle">
                <h2 className="mt-10 mb-10">
                    Doces e Compotas <br /> de Physalis
                </h2>
                <p className="medium-text padding-bottom30">
                    Os nossos doces têm as bagas de Physalis como matéria prima
                    e contam com um teor mínimo de 60% de fruta.
                </p>
            </div>
            <div className="product-list-grid">
                {jams.map((product, idx) => (
                    <Product key={idx} product={product} />
                ))}
            </div>
            {/* <div className="online-store-button">
                <Button
                    href="/contacts"
                    type="anchor"
                    buttonStyle="btn--primary"
                    buttonSize="btn--large"
                >
                    <i className="fa fa-shopping-basket mr-10"> </i>Loja Online
                </Button>
            </div> */}
            <div className="jam-usage mt-50">
                <h3 className="large-text blue-text">
                    Sugestões de utilização
                </h3>
                <div className="product-usage-suggestions mt-20 mb-50">
                    <div>
                        <p className="navbar-title">Queijos</p>
                        <p className="normal-text blue-text mt-5 mb-10">
                            Excelentes com os mais variados queijos.
                        </p>
                        <p className="navbar-title">Molhos</p>
                        <p className="normal-text blue-text mt-5 mb-10">
                            Na elaboração de molhos para carnes ou peixes,
                            marcam positivamente a diferença.
                        </p>
                    </div>
                    <div>
                        <p className="navbar-title">Recheio</p>
                        <p className="normal-text blue-text mt-5 mb-10">
                            Com crepes ou panquecas; como recheio ou cobertura
                            de bolos, cheesecakes ou panna cotta; como topping
                            em gelados e iogurtes.
                        </p>
                        <p className="navbar-title">Aperitivo</p>
                        <p className="normal-text blue-text mt-5 mb-10">
                            Simplesmente com pão ou bolachas, como aperitivo, ao
                            pequeno almoço ou em sobremesas.
                        </p>
                    </div>
                </div>
            </div>
            <h3 className="grey-text">Descubra as nossas bagas</h3>
            <div className="discover-section-grid">
                {berries.map((discoverProduct, idx) => (
                    <DiscoverProduct
                        key={idx}
                        discoverProduct={discoverProduct}
                    />
                ))}
            </div>
        </div>
    );
}

export default JamList;
