import React from 'react';

function Contact(props) {
    return (
        <div className="single-contact-component">
            <p className="medium-text">{props.sentence}</p>
            {props.contact && <h3 className="blue-text">{props.contact}</h3>}
            {props.icon && (
                <a href={props.link} target="_blank" rel="noopener noreferrer">
                    <i className={`contact-icon ${props.icon}`}></i>
                </a>
            )}
        </div>
    );
}

export default Contact;
