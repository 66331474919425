import React from 'react';

function Checkbox(props) {
    return (
        <label className="check-container">
            <input type="checkbox" />
            <label for="checkbox">{props.option}</label>
            <span className="checkmark"></span>
        </label>
    );
}
export default Checkbox;
