import React from 'react';

function CardIngredients(props) {
    return (
        <div className="card">
            <div className="title">
                <p>Ingredientes</p>
            </div>
            <div className="ingredients-content">
                <div className="physalis">
                    {props.percentage && (
                        <h3 className="blue-text">{props.percentage}</h3>
                    )}
                    <p>Bagas de Physalis peruviana</p>
                </div>
                {props.ingredient && (
                    <div className="extra-ingredient">
                        <p>{props.ingredient}</p>
                    </div>
                )}
                {props.sugar && (
                    <div className="sugar">
                        <p>Açúcar</p>
                    </div>
                )}
                {props.specialIngredient && (
                    <div className="extra-ingredient">
                        <p>{props.specialIngredient}</p>
                    </div>
                )}
                {props.secondSpecialIngredient && (
                    <div className="extra-ingredient">
                        <p>{props.secondSpecialIngredient}</p>
                    </div>
                )}
                {props.salt && (
                    <div className="salt">
                        <p>Sal</p>
                    </div>
                )}
                <div className="extra-info">
                    {props.sulfitos && <p>Pode conter sulfitos.</p>}
                    <p>Não contém corantes nem conservantes.</p>
                    <p>Sem glúten e sem lactose.</p>
                </div>
            </div>
        </div>
    );
}
export default CardIngredients;
