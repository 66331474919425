import React from 'react';
import { Link } from 'react-router-dom';

import SearchBar from '../searchbar/searchbar';

function Topnav() {
    return (
        <nav className="position-fixed">
            <input type="checkbox" id="check" />
            <label htmlFor="check" className="checkbtn">
                <i className="fa fa-bars menu"></i>
            </label>
            <Link to="/" className="logo-nav">
                <svg
                    width="214"
                    height="54"
                    viewBox="0 0 214 54"
                    fill="#0f2043"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M37.8572 14.8327C39.2525 16.4468 39.9505 18.5639 39.9505 21.1817C39.9505 23.7416 39.2746 25.8074 37.9233 27.3789C36.5715 28.9494 34.7944 29.7344 32.5909 29.7344H28.4484C28.3305 29.7344 28.2721 29.7927 28.2721 29.9089V42.5648C28.2721 42.8555 28.1245 43.0006 27.8315 43.0006H24.4822C24.188 43.0006 24.0415 42.8555 24.0415 42.5648V12.8466C24.0415 12.5559 24.1886 12.4097 24.4822 12.4097H32.3706C34.6324 12.4102 36.4613 13.2175 37.8572 14.8327ZM34.6181 25.0001C35.3524 24.0836 35.7198 22.8405 35.7198 21.2689C35.7198 19.6696 35.3524 18.3964 34.6181 17.4505C33.8833 16.5057 32.9137 16.0323 31.7095 16.0323H28.4484C28.3305 16.0323 28.2721 16.0906 28.2721 16.2068V26.2001C28.2721 26.3163 28.3305 26.3736 28.4484 26.3736H31.7095C32.9137 26.3747 33.8833 25.9165 34.6181 25.0001Z" />
                    <path d="M55.6385 12.4539H58.9878C59.2809 12.4539 59.4285 12.6 59.4285 12.8908V42.5653C59.4285 42.8561 59.2809 43.0012 58.9878 43.0012H55.6385C55.3444 43.0012 55.1978 42.8561 55.1978 42.5653V29.6913C55.1978 29.5762 55.1389 29.5178 55.0216 29.5178H48.3671C48.2492 29.5178 48.1908 29.5762 48.1908 29.6913V42.5659C48.1908 42.8566 48.0432 43.0017 47.7502 43.0017H44.4009C44.1067 43.0017 43.9602 42.8566 43.9602 42.5659V12.8913C43.9602 12.6006 44.1067 12.4544 44.4009 12.4544H47.7502C48.0438 12.4544 48.1908 12.6006 48.1908 12.8913V25.7212C48.1908 25.8385 48.2492 25.8958 48.3671 25.8958H55.0216C55.1384 25.8958 55.1978 25.8385 55.1978 25.7212V12.8913C55.1978 12.6 55.3444 12.4539 55.6385 12.4539Z" />
                    <path d="M69.7843 42.5217V30.0404L69.7402 29.8222L63.6146 12.9344L63.5706 12.7599C63.5706 12.5564 63.7028 12.4539 63.9672 12.4539H67.4927C67.7571 12.4539 67.9334 12.5711 68.0215 12.8035L71.7674 24.8479C71.7966 24.9062 71.8401 24.9352 71.8996 24.9352C71.958 24.9352 72.0021 24.9062 72.0318 24.8479L75.7777 12.8035C75.8659 12.5711 76.0421 12.4539 76.3066 12.4539H79.8321C80.1549 12.4539 80.2728 12.6148 80.1846 12.9344L74.059 29.8222L74.015 30.0404V42.5217C74.015 42.8124 73.8673 42.9575 73.5743 42.9575H70.225C69.9308 42.9575 69.7843 42.8124 69.7843 42.5217Z" />
                    <path d="M84.7453 41.1902C83.3202 39.7507 82.6079 37.8671 82.6079 35.5395V34.4049C82.6079 34.1141 82.7544 33.969 83.0486 33.969H86.3097C86.6033 33.969 86.7504 34.1141 86.7504 34.4049V35.3213C86.7504 36.6605 87.0876 37.7291 87.764 38.5293C88.4399 39.3295 89.3064 39.7288 90.3641 39.7288C91.4218 39.7288 92.2954 39.3443 92.9862 38.573C93.6759 37.8027 94.0218 36.7903 94.0218 35.5395C94.0218 34.6956 93.8384 33.9543 93.471 33.3139C93.1035 32.6746 92.6188 32.0991 92.0167 31.5902C91.414 31.0818 90.4368 30.3317 89.0861 29.3433C87.5877 28.2376 86.4122 27.2923 85.5606 26.5062C84.7084 25.7213 83.9884 24.7759 83.4012 23.6702C82.8134 22.5656 82.5198 21.2701 82.5198 19.7869C82.5198 17.4304 83.2249 15.5605 84.6351 14.1788C86.0453 12.7981 87.8808 12.1064 90.1438 12.1064C92.4937 12.1064 94.381 12.8478 95.8066 14.332C97.2312 15.8152 97.944 17.7648 97.944 20.1791V21.2701C97.944 21.5608 97.7964 21.707 97.5033 21.707H94.2422C93.948 21.707 93.8015 21.5608 93.8015 21.2701V20.0918C93.8015 18.7827 93.4633 17.7288 92.7879 16.9286C92.1114 16.1283 91.2301 15.7279 90.1438 15.7279C89.1147 15.7279 88.2774 16.0847 87.6318 16.7976C86.9851 17.5106 86.6623 18.5066 86.6623 19.7869C86.6623 20.9504 86.9851 21.9329 87.6318 22.7315C88.2774 23.5328 89.5268 24.6014 91.3777 25.9395C93.1405 27.2486 94.4691 28.3112 95.366 29.1251C96.2617 29.9401 96.9525 30.8341 97.4372 31.8089C97.922 32.7837 98.1644 33.9407 98.1644 35.2777C98.1644 37.7214 97.4444 39.6787 96.005 41.1471C94.565 42.6172 92.6849 43.3514 90.3641 43.3514C88.0427 43.3509 86.1698 42.6303 84.7453 41.1902Z" />
                    <path d="M114.03 42.609L113.059 37.5899C113.059 37.5327 113.037 37.4885 112.993 37.459C112.949 37.4301 112.898 37.4154 112.839 37.4154H105.656C105.597 37.4154 105.546 37.4301 105.502 37.459C105.458 37.489 105.436 37.5327 105.436 37.5899L104.51 42.609C104.48 42.8703 104.318 43.0012 104.025 43.0012H100.676C100.528 43.0012 100.419 42.9575 100.346 42.8703C100.272 42.783 100.25 42.6679 100.279 42.5217L106.846 12.8472C106.904 12.5853 107.066 12.4539 107.33 12.4539H111.253C111.546 12.4539 111.708 12.5848 111.737 12.8472L118.348 42.5217V42.609C118.348 42.8703 118.216 43.0012 117.951 43.0012H114.514C114.25 43.0012 114.087 42.8703 114.03 42.609ZM106.317 34.0988H112.178C112.266 34.0988 112.31 34.0415 112.31 33.9253L109.314 18.4335C109.284 18.3751 109.255 18.3462 109.226 18.3462C109.196 18.3462 109.167 18.3751 109.138 18.4335L106.185 33.9253C106.185 34.041 106.229 34.0988 106.317 34.0988Z" />
                    <path d="M121.653 42.5653V12.8908C121.653 12.6 121.8 12.4539 122.094 12.4539H125.443C125.736 12.4539 125.883 12.6 125.883 12.8908V39.2051C125.883 39.3213 125.942 39.3797 126.06 39.3797H135.931C136.225 39.3797 136.372 39.5259 136.372 39.8155V42.5653C136.372 42.8561 136.225 43.0012 135.931 43.0012H122.094C121.8 43.0012 121.653 42.8561 121.653 42.5653Z" />
                    <path d="M139.589 42.5653V12.8908C139.589 12.6 139.735 12.4539 140.029 12.4539H143.378C143.672 12.4539 143.819 12.6 143.819 12.8908V42.5653C143.819 42.8561 143.672 43.0012 143.378 43.0012H140.029C139.735 43.0012 139.589 42.8561 139.589 42.5653Z" />
                    <path d="M162.152 12.4539H165.457C165.749 12.4539 165.897 12.6001 165.897 12.8909V42.5654C165.897 42.8561 165.749 43.0012 165.457 43.0012H161.666C161.43 43.0012 161.269 42.885 161.181 42.6527L153.689 22.7091C153.66 22.6507 153.616 22.6294 153.557 22.6431C153.498 22.6578 153.469 22.6944 153.469 22.7527L153.513 42.5648C153.513 42.8556 153.365 43.0007 153.073 43.0007H149.723C149.43 43.0007 149.282 42.8556 149.282 42.5648V12.8903C149.282 12.5996 149.43 12.4534 149.723 12.4534H153.469C153.705 12.4534 153.866 12.5706 153.954 12.803L161.49 32.7466C161.519 32.8339 161.563 32.8704 161.622 32.8552C161.68 32.8415 161.71 32.7902 161.71 32.703V12.8909C161.711 12.6001 161.857 12.4539 162.152 12.4539Z" />
                    <path d="M182.952 42.609L181.983 37.5899C181.983 37.5327 181.96 37.4885 181.916 37.459C181.872 37.4301 181.82 37.4154 181.762 37.4154H174.578C174.519 37.4154 174.469 37.4301 174.425 37.459C174.381 37.489 174.359 37.5327 174.359 37.5899L173.433 42.609C173.403 42.8703 173.241 43.0012 172.948 43.0012H169.599C169.452 43.0012 169.342 42.9575 169.269 42.8703C169.194 42.783 169.173 42.6679 169.202 42.5217L175.768 12.8472C175.827 12.5853 175.989 12.4539 176.254 12.4539H180.175C180.469 12.4539 180.63 12.5848 180.661 12.8472L187.271 42.5217V42.609C187.271 42.8703 187.139 43.0012 186.874 43.0012H183.436C183.173 43.0012 183.011 42.8703 182.952 42.609ZM175.24 34.0988H181.101C181.189 34.0988 181.233 34.0415 181.233 33.9253L178.237 18.4335C178.208 18.3751 178.178 18.3462 178.149 18.3462C178.119 18.3462 178.09 18.3751 178.06 18.4335L175.108 33.9253C175.108 34.041 175.152 34.0988 175.24 34.0988Z" />
                    <path d="M198.279 42.5029C197.268 42.4985 197.233 42.4036 197.199 42.2056V41.4042C197.201 41.2062 197.275 41.2051 198.285 41.2095L200.253 41.2193C201.09 41.2215 201.278 41.2013 201.278 41.4004V41.8608C201.239 42.6431 201.259 42.517 200.246 42.5116L198.279 42.5029Z" />
                    <path d="M197.199 42.2056C197.199 44.6941 195.798 46.4756 193.344 47.0086C189.993 47.7363 187.778 45.1496 187.301 42.5263L183.106 43.8251C183.987 48.663 188.749 51.7669 193.783 50.8674C198.214 50.0759 201.202 46.3785 201.254 42.2056H197.199Z" />
                    <path d="M183.37 45.2569L182.454 40.3486L186.542 39.376L187.65 44.3116L183.37 45.2569Z" />
                    <path d="M15.9849 25.0395C16.7341 24.4122 16.8206 24.4602 16.971 24.5857L17.4811 25.1759C17.6061 25.3237 17.5527 25.3696 16.8046 25.9974L15.3465 27.2193C14.7273 27.7403 14.6012 27.8723 14.475 27.7266L14.1814 27.3868C13.7126 26.7846 13.7782 26.8904 14.5274 26.262L15.9849 25.0395Z" />
                    <path d="M26.9054 12.4028H20.251V16.7165H26.9054V12.4028Z" />
                    <path d="M16.8261 24.4336C16.3414 23.7168 16.0593 22.8353 16.0208 21.8179C15.8902 18.4223 18.8522 16.7231 21.5448 16.722L20.9956 12.4028C16.0307 12.4061 12.1041 16.4973 12.1074 21.5632C12.1085 23.6344 12.7949 25.5093 13.9379 27.0066L16.8261 24.4336Z" />
                </svg>
            </Link>
            <ul>
                <div className="dropdown">
                    <li className="links">
                        <Link to="/productCategories" className="dropdown-btn">
                            Produtos
                        </Link>
                        <i className="fa fa-caret-down padding-left10"></i>
                    </li>
                    <div className="dropdown-content">
                        <Link to="/jamList" className="normal-text">
                            Doces e Compotas <br /> de Physalis
                        </Link>
                        <Link to="/berryList" className="normal-text">
                            Desidratados de Physalis
                        </Link>
                        <Link to="/preservesList" className="normal-text">
                            Conservas e outros <br /> de Physalis
                        </Link>
                    </div>
                </div>
                <li className="links">
                    <Link to="/about">Sobre nós</Link>
                </li>
                <li className="links">
                    <Link to="/contacts">Contactos</Link>
                </li>
                {/* <li className="links">
                    <a
                        href="/contacts"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fa fa-shopping-basket mr-10"></i>
                        Loja Online
                    </a>
                </li> */}
                <div className="search-container">
                    <SearchBar />
                </div>
            </ul>
        </nav>
    );
}

export default Topnav;
