import React from 'react';
import { Link } from 'react-router-dom';

function HorizontalMenu() {
    return (
        <div className="scrollable-menu padding-top70">
            <span className="scrollable-menu-title">Produtos</span>
            <nav className="nav vertical-align-middle scroll">
                <Link to="/originalJam" className="nav-item">
                    Doce de Physalis
                </Link>
                <Link to="/hortela" className="nav-item">
                    Hortelã
                </Link>
                <Link to="/anis" className="nav-item">
                    Anis
                </Link>
                <Link to="/eucalipto" className="nav-item">
                    Eucalipto
                </Link>
                <Link to="/vinhoporto" className="nav-item">
                    Vinho do Porto
                </Link>
                <Link to="/toranja" className="nav-item">
                    Toranja
                </Link>
                <Link to="/originalExtraJam" className="nav-item">
                    Compota Extra de Physalis
                </Link>
                <Link to="/chocolateJam" className="nav-item">
                    Doce Chocolate
                </Link>
                <Link to="/originalBerry" className="nav-item">
                    Bagas desidratadas
                </Link>
                <Link to="/chocolate" className="nav-item">
                    Baga com Chocolate
                </Link>
                <Link to="/sauce" className="nav-item">
                    Bagas em Calda
                </Link>
                <Link to="/pickles" className="nav-item">
                    Bagas em Pickles
                </Link>
            </nav>
        </div>
    );
}

export default HorizontalMenu;
