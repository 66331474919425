import React from 'react';
import './App.scss';
import Topnav from './components/topnav/topnav';
import Footer from './components/footer/footer';
import LandingPage from './pages/landingPage';
import ProductCategories from './pages/productCategories';
import About from './pages/about';
import Contacts from './pages/contacts';
import JamList from './pages/plp/jamList';
import BerryList from './pages/plp/berryList';
import OriginalJam from './pages/pdp/originalJam';
import Hortela from './pages/pdp/hortela';
import Anis from './pages/pdp/anis';
import Eucalipto from './pages/pdp/eucalipto';
//import VinhoPorto from './pages/pdp/vinhoPorto';
import Toranja from './pages/pdp/toranja';
import OriginalBerry from './pages/pdp/originalBerry';
import Chocolate from './pages/pdp/chocolate';
import SearchResults from './pages/searchResults';
import TermsConditions from './pages/termsConditions';
import OriginalExtraJam from './pages/pdp/originalExtraJam';
import ChocolateJam from './pages/pdp/chocolateJam';
import LightSpicy from './pages/pdp/lightSpicy';
import Spicy from './pages/pdp/spicy';
import Cinnamon from './pages/pdp/cinnamon';
import Pickles from './pages/pdp/pickles';
import Preserves from './pages/plp/preservesList';

//import Joana from './components/joana';
//import Miguel from './components/miguel';
//import Moura from './components/moura';

import {
    Switch,
    Route,
    BrowserRouter as Router,
    Redirect,
} from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import BerriesSauce from './pages/pdp/berriesSauce';

//import Example from './components/example/Example';

function App() {
    return (
        <Router>
            <ScrollMemory />
            <Topnav />
            <Switch>
                <Redirect exact from="/" to="home" />
                <Route path="/home">
                    <LandingPage />
                </Route>
                <Route path="/productCategories">
                    <ProductCategories />
                </Route>
                <Route path="/about">
                    <About />
                </Route>
                <Route path="/contacts">
                    <Contacts />
                </Route>
                <Route path="/jamList">
                    <JamList />
                </Route>
                <Route path="/berryList">
                    <BerryList />
                </Route>
                <Route path="/preservesList">
                    <Preserves />
                </Route>
                <Route path="/originalJam">
                    <OriginalJam />
                </Route>
                <Route path="/hortela">
                    <Hortela />
                </Route>
                <Route path="/anis">
                    <Anis />
                </Route>
                <Route path="/eucalipto">
                    <Eucalipto />
                </Route>
                {/* <Route path="/vinhoporto">
                    <VinhoPorto />
                </Route> */}
                <Route path="/toranja">
                    <Toranja />
                </Route>
                <Route path="/originalExtraJam">
                    <OriginalExtraJam />
                </Route>
                <Route path="/chocolateJam">
                    <ChocolateJam />
                </Route>
                <Route path="/originalberry">
                    <OriginalBerry />
                </Route>
                <Route path="/chocolate">
                    <Chocolate />
                </Route>
                <Route path="/lightSpicy">
                    <LightSpicy />
                </Route>
                <Route path="/spicy">
                    <Spicy />
                </Route>
                <Route path="/cinnamon">
                    <Cinnamon/>
                </Route>
                <Route path="/sauce">
                    <BerriesSauce />
                </Route>
                <Route path="/pickles">
                    <Pickles />
                </Route>
                <Route path="/searchResults">
                    <SearchResults />
                </Route>
                <Route path="/termsConditions">
                    <TermsConditions />
                </Route>
            </Switch>
            <Footer />
        </Router>
        /*<Router>
            <div className="app">
                <header className="app-header">
                    <nav className="app-nav">
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/joana">Joana</Link>
                            </li>
                            <li>
                                <Link to="/moura">Arnaldo</Link>
                            </li>
                            <li>
                                <Link to="/miguel">Miguel</Link>
                            </li>
                        </ul>
                    </nav>
                </header>
                <Switch>
                    <Route path="/joana">
                        <Joana />
                    </Route>
                    <Route path="/moura">
                        <Moura />
                    </Route>
                    <Route path="/miguel">
                        <Miguel />
                    </Route>
                    <Route path="/">
                        <div className="app-content">
                            <Example />
                        </div>
                    </Route>
                </Switch>
                <footer className="app-footer">
                    <p>
                        <span role="img" aria-label="Foot">
                            🦶
                        </span>{' '}
                        Some more amazing footer content
                    </p>
                </footer>
            </div>
        </Router>*/
    );
}

export default App;
