import React from 'react';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Sidebar from '../../components/sidebar/sidebar';
import DetailedProduct from '../../components/product/detailedProduct';
import DetailsHeading from '../../components/detailsHeading/detailsHeading';
import CardIngredients from '../../components/card/cardIngredients';
import CardNutritional from '../../components/card/cardNutritional';
//import SuggestedProductsHeading from '../../components/product/suggestedProductsHeading';
//import DiscoverProduct from '../../components/product/discoverProduct';
import HorizontalMenu from '../../components/sidebar/horizontalMenu';
import RotatingDiv from '../../components/rotatingDiv/rotatingDiv';

//import product images
import spicy from '../../images/products/picante-audaz.png';
//import fig from '../../images/products/fig.png';
//import blueberry from '../../images/products/blueberry.png';

function Spicy() {
    /*const suggestions = [
        {
            name: 'Doce de Figo Pingo de Mel',
            photo: fig,
            link: 'https://www.floryptus.pt/fig',
            className: 'floryptus-link',
        },
        {
            name: 'Doce de Mirtilo',
            photo: blueberry,
            link: '/originalberry',
            className: 'floryptus-link',
        },
    ];*/

    return (
        <>
            <div className="horizontal-menu">
                <HorizontalMenu />
            </div>
            <div className="breadcrumb-container">
                <Breadcrumb
                    home="Home"
                    section="Produtos"
                    category="Desidratados de Physalis"
                    name="Picante Audaz"
                ></Breadcrumb>
            </div>
            <div className="product-details-grid">
                <div className="sidebar-menu padding-top30 padding-left50">
                    <Sidebar />
                </div>
                <DetailedProduct
                    photo={spicy}
                    name="Picante Audaz"
                    color="orange-text"
                    category="Desidratados de Physalis"
                    description="Bagas de Physalis desidratadas com o aspeto de passas cuja cor pode variar desde o amarelo acastanhado até ao castanho-escuro, são condimentadas com pimenta cayenne e canela. As Physalis condimentadas 'Picante Audaz', são excelentes para consumir simples como snacks, numa pausa do trabalho e/ou na companhia de amigos, acompanhabdo ou não uma bebida."
                    weight="Frasco de 100g"
                    link="/contacts"
                    buttonColor="btn--original"
                    buttonText="Online"
                ></DetailedProduct>
                <div className="details">
                    <div className="details-title">
                        <DetailsHeading
                            text="Detalhes"
                            type="original"
                        ></DetailsHeading>
                    </div>
                    <div className="cards">
                        <div className="card-ingridients-container">
                            <CardIngredients
                                sugar="yes"
                                salt="yes"
                                ingredient="Pimenta Cayenne e Canela"
                            ></CardIngredients>
                            <RotatingDiv>
                                <div className="svg-original stripes-pattern-container"></div>
                            </RotatingDiv>
                        </div>
                        <div className="card-nutritional-container mb-100">
                            <CardNutritional
                                energy="994 kj / 237 kcal"
                                lipids="1.11 g"
                                saturados="0.00 g"
                                hydrates="49.58 g"
                                sugars="33.23 g"
                                fiber="19.03 g"
                                proteins="7.29 g"
                                salt="0.30 g"
                            ></CardNutritional>
                            <RotatingDiv>
                                <div className="svg-original stripes-pattern-container2"></div>
                            </RotatingDiv>
                        </div>
                    </div>
                </div>
                {/*
                <div className="suggestions-heading">
                    <SuggestedProductsHeading
                        question="Não resiste a doces de fruta?"
                        answer="Deixe-se levar:"
                        color="red-text"
                    ></SuggestedProductsHeading>
                </div>
                <div className="discover-section-grid">
                    {suggestions.map((discoverProduct, idx) => (
                        <DiscoverProduct
                            key={idx}
                            discoverProduct={discoverProduct}
                        />
                    ))}
                </div>
                    */}
            </div>
        </>
    );
}

export default Spicy;
