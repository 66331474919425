import React from 'react';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Sidebar from '../../components/sidebar/sidebar';
import DetailedProduct from '../../components/product/detailedProduct';
import DetailsHeading from '../../components/detailsHeading/detailsHeading';
import CardIngredients from '../../components/card/cardIngredients';
import CardNutritional from '../../components/card/cardNutritional';
//import SuggestedProductsHeading from '../../components/product/suggestedProductsHeading';
//import DiscoverProduct from '../../components/product/discoverProduct';
import HorizontalMenu from '../../components/sidebar/horizontalMenu';
import RotatingDiv from '../../components/rotatingDiv/rotatingDiv';

//import product images
import anis from '../../images/products/anis.png';
//import fig from '../../images/products/fig.png';

function Anis() {
    /*const suggestions = [
        {
            name: 'Doce de Figo Pingo de Mel',
            photo: fig,
            link: 'https://www.floryptus.pt/fig',
            className: 'floryptus-link',
        },
    ];*/

    return (
        <>
            <div className="horizontal-menu">
                <HorizontalMenu />
            </div>
            <div className="breadcrumb-container">
                <Breadcrumb
                    home="Home"
                    section="Produtos"
                    category="Doces de Physalis"
                    name="Anis"
                ></Breadcrumb>
            </div>
            <div className="product-details-grid">
                <div className="sidebar-menu padding-top30 padding-left50">
                    <Sidebar />
                </div>
                <DetailedProduct
                    photo={anis}
                    name="Anis"
                    color="light-brown-text"
                    category="Doces de Physalis"
                    description="Para os amantes de sabores mais intensos e perfumados, o Doce de Physalis com Anis promete aquecer corações."
                    weight="Frasco de 245g"
                    link="/contacts"
                    buttonColor="btn--anis-chocolate"
                    buttonText="Online"
                ></DetailedProduct>
                <div className="details">
                    <div className="details-title">
                        <DetailsHeading
                            text="Detalhes"
                            type="anis"
                        ></DetailsHeading>
                    </div>
                    <div className="cards">
                        <div className="card-ingridients-container">
                            <CardIngredients
                                percentage="60%"
                                sugar="yes"
                                salt="yes"
                                specialIngredient="Anis estrelado"
                            ></CardIngredients>
                            <RotatingDiv>
                                <div className="svg-anis stripes-pattern-container"></div>
                            </RotatingDiv>
                        </div>
                        <div className="card-nutritional-container mb-100">
                            <CardNutritional
                                energy="1197 kj / 282 kcal"
                                lipids="0.44 g"
                                saturados="0.02 g"
                                hydrates="65.75 g"
                                sugars="54.95 g"
                                fiber="3.97 g"
                                proteins="1.85 g"
                                salt="0.35 g"
                            ></CardNutritional>
                            <RotatingDiv>
                                <div className="svg-anis stripes-pattern-container2"></div>
                            </RotatingDiv>
                        </div>
                    </div>
                </div>
                {/*
                <div className="suggestions-heading">
                    <SuggestedProductsHeading
                        question="Por falar em aquecer corações..."
                        answer="Suspeitamos que vá gostar:"
                        color="light-brown-text"
                    ></SuggestedProductsHeading>
                </div>
                <div className="discover-section-grid">
                    {suggestions.map((discoverProduct, idx) => (
                        <DiscoverProduct
                            key={idx}
                            discoverProduct={discoverProduct}
                        />
                    ))}
                </div>
                    */}
            </div>
        </>
    );
}

export default Anis;
