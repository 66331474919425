import React from 'react';
import Button from '../../components/button/button';

function DetailedProduct(props) {
    return (
        <div className="product-details-grid">
            <div className="left-content-container">
                <img
                    src={props.photo}
                    className="product-detail-image"
                    alt="ProductImage"
                />
            </div>
            <div className="right-content-container mt-50">
                <h2 className={props.color}>{props.name}</h2>
                <p className="small-text-semibold padding-top20 blue-text">
                    Categoria
                </p>
                <p className="normal-text mb-20">{props.category}</p>
                <p className="small-text-semibold blue-text">Peso</p>
                <p className="normal-text mb-20">{`${props.weight}`}</p>
                <p className="small-text-semibold blue-text">Descrição</p>
                <p className="normal-text mb-30">{`${props.description}`}</p>
                <Button
                    href={props.link}
                    type="anchor"
                    buttonStyle={props.buttonColor}
                    buttonSize="btn--normal"
                >
                    <i className="fa fa-shopping-basket mr-10"></i>Comprar {props.buttonText}
                </Button>
                {props.berries && (
                    <Button
                        href={props.secondLink}
                        type="anchor"
                        buttonStyle={props.buttonColor}
                        buttonSize="btn--normal"
                    >
                        <i className="fa fa-shopping-basket mr-10"></i>Comprar 125g
                    </Button>
            )}
            </div>
        </div>
    );
}

export default DetailedProduct;
