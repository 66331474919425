import React from 'react';
import Breadcrumb from '../components/breadcrumb/breadcrumb';
import InlineJams from '../components/product/inlineJams';
import InlineBerries from '../components/product/inlineBerries';
import Explore from '../components/explore/explore';
import SuggestedProductsHeading from '../components/product/suggestedProductsHeading';
import DiscoverProduct from '../components/product/discoverProduct';

import originaljam from '../images/products/original.png';
import hortela from '../images/products/hortela.png';
import toranja from '../images/products/toranja.png';
import chocolate from '../images/products/chocolate.png';

function ProductCategories() {
    const suggestions = [
        {
            name: 'Original',
            photo: originaljam,
            link: '/originalberry',
            className: 'physalina-link',
        },
        {
            name: 'Hortelã',
            photo: hortela,
            link: '/hortela',
            className: 'physalina-link',
        },
        {
            name: 'Toranja',
            photo: toranja,
            link: '/toranja',
            className: 'physalina-link',
        },
        {
            name: 'Chocolate',
            photo: chocolate,
            link: '/chocolate',
            className: 'physalina-link',
        },
    ];

    return (
        <>
            <div className="breadcrumb-wrapper">
                <Breadcrumb home="Home" section="Produtos"></Breadcrumb>
            </div>
            <div className="headings-wrapper">
                <div className="title-subtitle">
                    <h1 className="mt-10 mb-10">Produtos</h1>
                    <h3 className="grey-text padding-bottom30">
                        Doces ou bagas? A escolha é sua!
                    </h3>
                </div>
            </div>
            <span className="medium-text blue-text please-drag">
                Arraste os produtos &nbsp;
                <i className="fa fa-angle-double-right"></i>
            </span>
            <div className="categories-wrapper">
                <div className="inline-jams-grid">
                    <InlineJams />
                </div>
                <div className="explore-jams">
                    <Explore
                        link="/jamList"
                        text="Doces e Compotas de Physalis (7)"
                    ></Explore>
                </div>
                <div className="inline-berries-grid">
                    <InlineBerries />
                </div>
                <div className="explore-berries">
                    <Explore
                        link="/berryList"
                        text="Desidratados de Physalis (5)"
                    ></Explore>
                </div>
            </div>
            <div className="suggestions-heading">
                <div className="general-info">
                    <h3 className="large-text blue-text">Informações gerais</h3>
                    <p className="normal-text blue-text mt-10 mb-50">
                        Todos os produtos PHYSALINA têm as bagas de physalis
                        como matéria prima principal, contendo um teor mínimo de
                        60% de fruta. Através dos nossos métodos de produção
                        artesanais, conseguimos obter os nossos produtos livres
                        de corantes e conservantes artificiais. <br /> <br />
                        Gradualmente temos vindo a criar novas variedades de
                        doces e outros novos produtos, sempre obtidos através da
                        adição de matérias primas naturais, tais como; ervas
                        aromáticas, frutos, etc., mas nunca através da adição de
                        essências sintetizadas laboratorialmente.
                    </p>
                </div>
                <SuggestedProductsHeading
                    question="Indeciso? Nós ajudamos!"
                    answer="Eis as nossas sugestões:"
                    color="grey-text"
                ></SuggestedProductsHeading>
            </div>
            <div className="discover-section-grid">
                {suggestions.map((discoverProduct, idx) => (
                    <DiscoverProduct
                        key={idx}
                        discoverProduct={discoverProduct}
                    />
                ))}
            </div>
        </>
    );
}

export default ProductCategories;
