import React from 'react';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Sidebar from '../../components/sidebar/sidebar';
import DetailedProduct from '../../components/product/detailedProduct';
import DetailsHeading from '../../components/detailsHeading/detailsHeading';
import CardIngredients from '../../components/card/cardIngredients';
import CardNutritional from '../../components/card/cardNutritional';
import SuggestedProductsHeading from '../../components/product/suggestedProductsHeading';
import DiscoverProduct from '../../components/product/discoverProduct';
import HorizontalMenu from '../../components/sidebar/horizontalMenu';
import RotatingDiv from '../../components/rotatingDiv/rotatingDiv';

//import product images
import originaljam from '../../images/products/original.png';
import originalberry from '../../images/products/originalberry.png';
import extra from '../../images/products/extra.png';

function OriginalJam() {
    const suggestions = [
        {
            name: 'Compota Extra de Physalis',
            photo: extra,
            link: '/originalextrajam',
            className: 'physalina-link',
        },
        {
            name: 'Bagas desidratadas',
            photo: originalberry,
            link: '/originalberry',
            className: 'physalina-link',
        },
    ];

    return (
        <>
            <div className="horizontal-menu">
                <HorizontalMenu />
            </div>
            <div className="breadcrumb-container">
                <Breadcrumb
                    home="Home"
                    section="Produtos"
                    category="Doces de Physalis"
                    name="Doce de Physalis"
                ></Breadcrumb>
            </div>
            <div className="product-details-grid">
                <div className="sidebar-menu padding-top30 padding-left50">
                    <Sidebar />
                </div>
                <DetailedProduct
                    photo={originaljam}
                    name="Doce de Physalis"
                    color="orange-text"
                    category="Doces de Physalis"
                    description="De sabor único, com a acidez (q.b.) caraterística da própria fruta a lembrar aromas cítricos,
                    é um doce excelente para acompanhar dos mais variados queijos até uma simples torrada."
                    weight="Frasco de 245g"
                    link="/contacts"
                    buttonColor="btn--original"
                    buttonText="Online"
                ></DetailedProduct>
                <div className="details">
                    <div className="details-title">
                        <DetailsHeading
                            text="Detalhes"
                            type="original"
                        ></DetailsHeading>
                    </div>
                    <div className="cards">
                        <div className="card-ingridients-container">
                            <CardIngredients
                                percentage="60%"
                                sugar="yes"
                                salt="yes"
                            />
                            <RotatingDiv>
                                <div className="svg-original stripes-pattern-container"></div>
                            </RotatingDiv>
                        </div>
                        <div className="card-nutritional-container">
                            <CardNutritional
                                energy="1186 kj / 280 kcal"
                                lipids="0.51 g"
                                saturados="0.05 g"
                                hydrates="65.75 g"
                                sugars="55.80 g"
                                fiber="3.50 g"
                                proteins="1.25 g"
                                salt="0.40 g"
                            ></CardNutritional>
                            <RotatingDiv>
                                <div className="svg-original stripes-pattern-container2"></div>
                            </RotatingDiv>
                        </div>
                    </div>
                </div>
                <div className="suggestions-heading">
                    <SuggestedProductsHeading
                        question="É fã do sabor original da Physalis?"
                        answer="Originalidade é connosco:"
                        color="orange-text"
                    ></SuggestedProductsHeading>
                </div>
                <div className="discover-section-grid">
                    {suggestions.map((discoverProduct, idx) => (
                        <DiscoverProduct
                            key={idx}
                            discoverProduct={discoverProduct}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}

export default OriginalJam;
