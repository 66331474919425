import React from 'react';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Sidebar from '../../components/sidebar/sidebar';
import DetailedProduct from '../../components/product/detailedProduct';
import DetailsHeading from '../../components/detailsHeading/detailsHeading';
import CardIngredients from '../../components/card/cardIngredients';
import CardNutritional from '../../components/card/cardNutritional';
import SuggestedProductsHeading from '../../components/product/suggestedProductsHeading';
import DiscoverProduct from '../../components/product/discoverProduct';
import HorizontalMenu from '../../components/sidebar/horizontalMenu';
import RotatingDiv from '../../components/rotatingDiv/rotatingDiv';
//import Radiobutton from '../../components/radiobutton/radiobutton';

//import product images
import originalberry from '../../images/products/originalberry.png';
import originaljam from '../../images/products/original.png';
import extra from '../../images/products/extra.png';

function OriginalBerry() {
    const suggestions = [
        {
            name: 'Doce de Physalis',
            photo: originaljam,
            link: '/originaljam',
            className: 'physalina-link',
        },
        {
            name: 'Compota Extra de Physalis',
            photo: extra,
            link: '/originalextrajam',
            className: 'physalina-link',
        },
    ];

    return (
        <>
            <div className="horizontal-menu">
                <HorizontalMenu />
            </div>
            <div className="breadcrumb-container">
                <Breadcrumb
                    home="Home"
                    section="Produtos"
                    category="Bagas de Physalis"
                    name="Bagas desidratadas"
                ></Breadcrumb>
            </div>
            <div className="product-details-grid">
                <div className="sidebar-menu padding-top30 padding-left50">
                    <Sidebar />
                </div>
                <DetailedProduct
                    photo={originalberry}
                    name="Bagas desidratadas"
                    color="orange-text"
                    category="Bagas de Physalis"
                    description="De sabor agridoce, as passas de physalis são um daqueles casos que “estranha-se mas depois entranha-se”.
                    Nem sempre se apreciam no primeiro contacto, com o seu gosto enigmático, ácido quanto baste, doce mas não tanto, enfim... único. 50g de bagas desidratadas equivalem a 250g de physalis frescas."
                    weight="50g ou 125g"
                    buttonText="50g"
                    link="/contacts"
                    secondLink="/contacts"
                    buttonColor="btn--original"
                    berries="yes"
                ></DetailedProduct>
                <div className="details">
                    <div className="details-title">
                        <DetailsHeading
                            text="Detalhes"
                            type="original"
                        ></DetailsHeading>
                    </div>
                    <div className="cards">
                        <div className="card-ingridients-container">
                            <CardIngredients
                                sugar="yes"
                                specialIngredient="Óleo de girassol"
                                sulfitos="yes"
                            />
                            <RotatingDiv>
                                <div className="svg-original-berry solid-pattern-container"></div>
                            </RotatingDiv>
                        </div>
                        <div className="card-nutritional-container">
                            <CardNutritional
                                energy="1186 kj / 280 kcal"
                                lipids="0.51 g"
                                saturados="0.05 g"
                                hydrates="65.75 g"
                                sugars="55.80 g"
                                fiber="3.50 g"
                                proteins="1.25 g"
                                salt="0.40 g"
                            ></CardNutritional>
                            <RotatingDiv>
                                <div className="svg-original-berry solid-pattern-container2"></div>
                            </RotatingDiv>
                        </div>
                    </div>
                </div>
                <div className="suggestions-heading">
                    <SuggestedProductsHeading
                        question="É fã do sabor original da Physalis?"
                        answer="Originalidade é connosco:"
                        color="orange-text"
                    ></SuggestedProductsHeading>
                </div>
                <div className="discover-section-grid">
                    {suggestions.map((discoverProduct, idx) => (
                        <DiscoverProduct
                            key={idx}
                            discoverProduct={discoverProduct}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}

export default OriginalBerry;
