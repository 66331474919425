import React from 'react';
import { Link } from 'react-router-dom';

import original from '../../images/products/original.png';
import hortela from '../../images/products/hortela.png';
import anis from '../../images/products/anis.png';
import eucalipto from '../../images/products/eucalipto.png';
//import vinhoporto from '../../images/products/vinhoporto.png';
import toranja from '../../images/products/toranja.png';

function InlineJams() {
    return (
        <div className="inline-jams-grid">
            <Link to="/originalJam" className="original-inline">
                <img src={original} alt="DoceOriginal" />
                <div className="inline-jams-hover"></div>
            </Link>
            <Link to="/hortela" className="hortela-inline">
                <img src={hortela} alt="Hortelã" />
                <div className="inline-jams-hover"></div>
            </Link>
            <Link to="/anis" className="anis-inline">
                <img src={anis} alt="Anis" />
                <div className="inline-jams-hover"></div>
            </Link>
            <Link to="/eucalipto" className="eucalipto-inline">
                <img src={eucalipto} alt="Eucalipto" />
                <div className="inline-jams-hover"></div>
            </Link>
            {/* <Link to="/vinhoporto" className="vinhoporto-inline">
                <img src={vinhoporto} alt="VinhoPorto" />
                <div className="inline-jams-hover"></div>
            </Link> */}
            <Link to="/toranja" className="toranja-inline">
                <img src={toranja} alt="Toranja" />
                <div className="inline-jams-hover"></div>
            </Link>
        </div>
    );
}

export default InlineJams;
