import React from 'react';
import { Link } from 'react-router-dom';

function Sidebar() {
    return (
        <div className="sidebar">
            {/* DOCES E COMPOTAS DE PHYSALIS */}
            <p className="normal-text"><b>Doces e Compotas <br /> de Physalis</b></p>
            <Link to="/originalJam">Doce de Physalis</Link>
            <Link to="/hortela">c/ Hortelã</Link>
            <Link to="/anis">c/ Anis</Link>
            <Link to="/eucalipto">c/ Eucalipto</Link>
            {/* <Link to="/vinhoporto">c/ Vinho do Porto</Link> */}
            <Link to="/toranja">c/ Toranja</Link>
            <Link to="/chocolateJam">c/ Chocolate</Link>
            <Link to="/originalExtraJam">Compota Extra de Physalis</Link>

            {/* DESIDRATADOS DE PHYSALIS */}
            <p className="normal-text mt-20"><b>Desidratados <br /> de Physalis</b></p>
            <Link to="/originalBerry">Bagas desidratadas</Link>
            <Link to="/chocolate">Bagas desidratadas c/ Chocolate</Link>
            <Link to="/lightSpicy">Picante Tímido</Link>
            <Link to="/spicy">Picante Audaz</Link>
            <Link to="/cinnamon">c/ Canela</Link>

            {/* CONSERVAS E OUTROS DE PHYSALIS */}
            <p className="normal-text mt-20"><b>Conservas e outros <br/>de Physalis</b></p>
            <Link to="/sauce">Bagas de Physalis em Calda</Link>
            <Link to="/pickles">Pickles de Physalis</Link>
        </div>
    );
}

export default Sidebar;
