import React from 'react';

function LandingHeader(props) {
    let typeBackground;
    switch (props.type) {
        case 'orange':
            typeBackground = 'background-orange';
            break;
        case 'blue':
            typeBackground = 'background-blue';
            break;
        default:
            typeBackground = 'background-grey';
            break;
    }

    const { title } = props;

    return (
        <div className="landing-header-container">
            <h3
                className={`landing-header white-text padding-top30 ${typeBackground}`}
            >
                {title}
            </h3>
        </div>
    );
}

export default LandingHeader;
