import React from 'react';
import { Link } from 'react-router-dom';

import original from '../../images/products/package/jam-original.png';
import hortela from '../../images/products/package/jam-hortela.png';
import anis from '../../images/products/package/jam-anis.png';
import eucalipto from '../../images/products/package/jam-eucalipto.png';
//import vinhoporto from '../../images/products/package/jam-vinhoporto.png';
import toranja from '../../images/products/package/jam-toranja.png';
import originalBerry from '../../images/products/originalberry.png';
import chocolate from '../../images/products/chocolate.png';
import chocolateJam from '../../images/products/package/chocolate-jam-package.png';
import originalExtraJam from '../../images/products/package/extra-jam-physalis-package.png';
import sauce from '../../images/products/package/sauce-package.png';
import pickles from '../../images/products/package/pickles-package.png';
import lightSpicy from '../../images/products/picante-timido.png';
import spicy from '../../images/products/picante-audaz.png';
import cinnamon from '../../images/products/com-canela.png';

function RotatingBanner(props) {
    const products = [
        {
            link: '/originalJam',
            className: 'first-product original-hover',
            src: original,
            alt: 'DoceOriginal',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/hortela',
            className: 'hortela-hover',
            src: hortela,
            alt: 'Hortelã',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/anis',
            className: 'anis-hover',
            src: anis,
            alt: 'Anis',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/eucalipto',
            className: 'eucalipto-hover',
            src: eucalipto,
            alt: 'Eucalipto',
            hoverStyle: 'banner-product-hover',
        },
        /* {
            link: '/vinhoporto',
            className: 'vinhoporto-hover',
            src: vinhoporto,
            alt: 'VinhoPorto',
            hoverStyle: 'banner-product-hover',
        }, */
        {
            link: '/toranja',
            className: 'toranja-hover',
            src: toranja,
            alt: 'Toranja',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/chocolateJam',
            className: 'anis-hover',
            src: chocolateJam,
            alt: 'Doce de Physalis com Chocolate',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/originalExtraJam',
            className: 'original-berry-hover',
            src: originalExtraJam,
            alt: 'Compota Extra de Physalis',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/sauce',
            className: 'original-hover',
            src: sauce,
            alt: 'Bagas em Calda',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/pickles',
            className: 'original-berry-hover',
            src: pickles,
            alt: 'Bagas em Pickles',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/originalBerry',
            className: 'original-berry-hover resized-berry-images',
            src: originalBerry,
            alt: 'BagaOriginal',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/chocolate',
            className: 'chocolate-hover resized-berry-images',
            src: chocolate,
            alt: 'BagaChocolate',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/lightSpicy',
            className: 'anis-hover',
            src: lightSpicy,
            alt: 'Picante Tímido',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/spicy',
            className: 'original-hover',
            src: spicy,
            alt: 'Picante Audaz',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/cinnamon',
            className: 'anis-hover',
            src: cinnamon,
            alt: 'c/ Canela',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/originalJam',
            className: 'original-hover',
            src: original,
            alt: 'DoceOriginal',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/hortela',
            className: 'hortela-hover',
            src: hortela,
            alt: 'Hortelã',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/anis',
            className: 'anis-hover',
            src: anis,
            alt: 'Anis',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/eucalipto',
            className: 'eucalipto-hover',
            src: eucalipto,
            alt: 'Eucalipto',
            hoverStyle: 'banner-product-hover',
        },
        {
            link: '/toranja',
            className: 'toranja-hover',
            src: toranja,
            alt: 'Toranja',
            hoverStyle: 'banner-product-hover',
        },
    ];

    return (
        <div className="banner-container">
            <div className="product-banner">
                {products.map((product, idx) => (
                    <Link
                        to={product.link}
                        className={product.className}
                        key={idx}
                    >
                        <img src={product.src} alt={product.alt} />
                        <div className={product.hoverStyle}></div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default RotatingBanner;
